<template>
<router-link
  :to="{ name: 'scenarioDetail', params: { id: scenario.id } }"
>
  <div class="card card-custom card-stretch">
    <div class="card-body  text-center">
      <div class="d-none d-md-block py-1" style="max-height: 230px; min-height: 230px; overflow: hidden; position: relative;">
        <img
          :src="scenario.background ? scenario.background.uri  : '/assets/media/bipart/idea-default.svg'"
          class="align-self-center w-100"
          style="position: absolute; margin: auto; top: 0;left: 0;right: 0;" 
          alt="Avatar"
        />
      </div>
      <div class="mt-3 text-dark font-weight-bolder text-hover-primary font-size-h5 text-left">
          {{ scenario.title }}
      </div>
    </div>
  </div>
</router-link>

</template>

<script>
export default {
  name: "ScenarioCard",
  props: ["scenario", "index"],
  filters: {
    truncate(text, length, clamp){
      clamp = clamp || '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    } 
  }
};
</script>

<style scoped></style>
